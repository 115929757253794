// src/components/Navbar.js

import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    setExpanded(false);
    if (path === '/') {
      navigate('/');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Navbar 
      expand="lg" 
      fixed="top" 
      className="navbar navbar-dark"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container fluid className="navbar-container">
        <Link to="/" className="navbar-brand" onClick={() => handleNavClick('/')}>
          KH Solve
        </Link>
        <Navbar.Toggle aria-controls="navbarNav" className="navbar-toggler" />
        <Navbar.Collapse id="navbarNav" className="collapse navbar-collapse">
          <Nav className="ms-auto navbar-nav">
            <Link to="/" className="nav-link" onClick={() => handleNavClick('/')}>
              Home
            </Link>
            <Link to="/#services" className="nav-link" onClick={handleNavClick}>
              Services
            </Link>
            <Link to="/#about" className="nav-link" onClick={handleNavClick}>
              About Us
            </Link>
            <Link to="/#contact" className="nav-link" onClick={handleNavClick}>
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
