// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <Container className="text-center">
        {/* Replace the anchor with a button for 'Back to top' */}
        <p className="mb-2">
          <button onClick={scrollToTop} className="btn btn-link text-decoration-none back-to-top">
            Back to top
          </button>
        </p>
        <div className="footer-icons mb-2">
          <ul className="social-icons list-inline">
            <li className="list-inline-item"><strong>Follow:</strong></li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/company/kh-solve/" rel="nofollow noopener noreferrer" className="text-decoration-none">
                <i className="bi bi-linkedin"></i> LinkedIn
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.x.com" rel="nofollow noopener noreferrer" className="text-decoration-none">
                <i className="bi bi-x"></i> X
              </a>
            </li>
          </ul>
        </div>
        <p className="copyright text-center mb-0">
          &copy; {new Date().getFullYear()} - KH Solve · 
          <Link to="/privacy" className="text-decoration-none"> Privacy</Link> · 
          <Link to="/terms" className="text-decoration-none"> Terms</Link>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;

