// src/components/PageTransition.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTransition = () => {
  const location = useLocation();

  useEffect(() => {
    // Only scroll to top if we're navigating to a new page (not an anchor)
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.hash]); // Add location.hash to the dependency array

  return null;
};

export default PageTransition;