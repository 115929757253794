// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import NavigationBar from './components/Navbar';
import HeroBanner from './components/HeroBanner';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BusinessAutomation from './components/BusinessAutomation';
import MachineLearning from './components/MachineLearning';
import MathematicalModelling from './components/MathematicalModelling';
import DataScience from './components/DataScience';
import Privacy from './components/Privacy';
import CookieConsent from './components/CookieConsent';
import PageTransition from './components/PageTransition';
import './styles/index.css';
import { useAnalytics } from './hooks/useAnalytics';
import Terms from './components/Terms';
import SEO from './components/SEO';

function ScrollToHashElement() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    const hash = location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  return null;
}

const AppContent = () => {
  useAnalytics('Home Page');
  
  return (
    <div className="app">
      <SEO />
      <ScrollToHashElement />
      <PageTransition />
      <NavigationBar />
      <main>
        <Routes>
          <Route path="/" element={
            <>
              <HeroBanner />
              <Services />
              <AboutUs />
              <Contact />
            </>
          } />
          <Route path="/business-automation" element={<BusinessAutomation />} />
          <Route path="/machine-learning" element={<MachineLearning />} />
          <Route path="/mathematical-modelling" element={<MathematicalModelling />} />
          <Route path="/data-science" element={<DataScience />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </main>
      <Footer />
      <CookieConsent />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;

