// src/components/BusinessAutomationContent.js
const BusinessAutomationContent = {
    hero: {
      title: "Business Process Automation",
      description: "Transform your business operations with intelligent automation solutions that streamline workflows, reduce costs, and empower your team to focus on strategic growth.",
      icon: "/assets/autom_icon.svg"
    },
    intro: {
      heading: "How Automation Transforms Your Business",
      paragraph: "Our automation solutions streamline your operations and enhance productivity, enabling your team to focus on strategic initiatives that drive business growth."
    },
    capabilities: [
      {
        title: "Workflow Optimisation",
        description: "Streamline processes and eliminate bottlenecks with intelligent automation"
      },
      {
        title: "Error Reduction",
        description: "Minimise human error and ensure consistent, high-quality outputs"
      },
      {
        title: "Resource Efficiency",
        description: "Optimise resource allocation and reduce operational costs"
      },
      {
        title: "Scalable Solutions",
        description: "Implement systems that grow and adapt with your business needs"
      }
    ],
    benefits: {
      heading: "Why Choose Business Automation?",
      items: [
        {
          title: "Streamlined Operations",
          description: "Automate repetitive tasks and workflows to increase efficiency and reduce operational costs.",
          icon: "assets/streamline_icon.svg"
        },
        {
          title: "Enhanced Accuracy",
          description: "Minimise human error and ensure consistent, high-quality results across all processes.",
          icon: "assets/accuracy_icon.svg"
        },
        {
          title: "Scalable Growth",
          description: "Build automated systems that grow with your business, maintaining efficiency at any scale.",
          icon: "assets/growth_icon.svg"
        }
      ]
    },
    implementation: {
      heading: "Our Implementation Process",
      steps: [
        {
          title: "Process Analysis",
          description: "We identify automation opportunities and map out your current business processes in detail."
        },
        {
          title: "Solution Design",
          description: "Our team designs custom automation solutions tailored to your specific workflow needs."
        },
        {
          title: "Implementation & Training",
          description: "We implement the automation systems and equipt your team to maximise their effectiveness."
        }
      ]
    },
    cta: {
      heading: "Ready to Automate Your Success?",
      paragraph: "Let's explore how automation can streamline your business processes."
    }
  };
  
  export default BusinessAutomationContent;