import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.gtag("consent", "default", {
      'analytics_storage': 'denied',
      'ad_storage': 'denied'
    });

    const consent = localStorage.getItem('cookieConsent');
    if (!consent && location.pathname !== '/privacy') {
      setShowBanner(true);
    } else if (consent === 'accepted') {
      window.gtag("consent", "update", {
        'analytics_storage': 'granted',
        'ad_storage': 'denied'
      });
      ReactGA.initialize('G-KMMEEK4YL9');
    }
  }, [location]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
    window.gtag("consent", "update", {
      'analytics_storage': 'granted',
      'ad_storage': 'denied'
    });
    ReactGA.initialize('G-KMMEEK4YL9');
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setShowBanner(false);
    window.gtag("consent", "update", {
      'analytics_storage': 'denied',
      'ad_storage': 'denied'
    });
  };

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setShowBanner(false);
    navigate('/privacy');
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    setShowBanner(false);
    navigate('/terms');
  };

  if (!showBanner) return null;

  return (
    <>
      <div className="cookie-overlay" />
      <div className="cookie-consent">
        <div className="cookie-content">
          <h2>Cookie Settings</h2>
          <div className="cookie-description">
            <p>We use cookies to analyze website traffic and improve your experience.</p>
            <div className="cookie-types">
              <div className="cookie-type">
                <div className="cookie-type-header">
                  <div className="cookie-icon essential">
                    <span>✓</span>
                  </div>
                  <div className="cookie-info">
                    <h3>Essential Cookies</h3>
                    <p>Required for basic website functionality. These cannot be disabled.</p>
                  </div>
                </div>
              </div>
              <div className="cookie-type">
                <div className="cookie-type-header">
                  <div className="cookie-icon analytics">
                    <span>📊</span>
                  </div>
                  <div className="cookie-info">
                    <h3>Analytics Cookies</h3>
                    <p>Help us understand how our website is used.</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="cookie-policy-link">
              View our <a href="/privacy" onClick={handlePrivacyClick}>Privacy Policy</a> and{' '}
              <a href="/terms" onClick={handleTermsClick}>Terms of Service</a>
            </p>
          </div>
          <div className="cookie-buttons">
            <Button onClick={handleDecline} variant="outline-secondary" className="decline-button">
              Decline Analytics
            </Button>
            <Button onClick={handleAccept} variant="primary" className="accept-button">
              Accept All
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsent; 