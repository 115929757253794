import React from 'react';
import ServicePage from './ServicePage';
import machineLearningContent from './MachineLearningContent';
import SEO from './SEO';

const MachineLearning = () => (
  <>
    <SEO 
      title="Machine Learning & AI Solutions | KH Solve"
      description="Leverage cutting-edge machine learning and AI solutions to transform your business. Our PhD-level expertise helps implement predictive modeling and AI-driven automation for real business advantages."
      path="/machine-learning"
      type="service"
    />
    <ServicePage content={machineLearningContent} />
  </>
);

export default MachineLearning; 