import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ 
  title,
  description,
  path,
  imageUrl = '/assets/kh-solve-banner.jpg',
  type = 'website'
}) => {
  const siteUrl = 'https://www.kh-solve.co.uk';
  const defaultTitle = 'KH Solve - Business Automation & AI Solutions';
  const defaultDescription = 'Expert business automation, machine learning, and data analysis solutions. Transform your business with our PhD-level expertise in mathematics and statistics.';
  
  const fullUrl = `${siteUrl}${path || ''}`;
  const fullImageUrl = `${siteUrl}${imageUrl}`;
  
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'ProfessionalService',
    '@id': siteUrl,
    name: 'KH Solve',
    description: description || defaultDescription,
    url: siteUrl,
    logo: {
      "@type": "ImageObject",
      "url": "https://www.kh-solve.co.uk/assets/KHlogo.svg",
      "width": "600",
      "height": "auto",
      "caption": "KH Solve - Business Automation & AI Solutions"
    },
    image: fullImageUrl,
    telephone: '+44-1234-567890',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'UK'
    },
    sameAs: [
      'https://www.linkedin.com/company/kh-solve/',
      'https://www.x.com/khsolve'
    ],
    areaServed: {
      '@type': 'Country',
      name: 'United Kingdom'
    },
    priceRange: '££',
    knowsAbout: [
      'Business Process Automation',
      'Machine Learning',
      'Data Analysis',
      'Mathematical Modelling',
      'Bayesian Statistics',
      'Artificial Intelligence'
    ]
  };

  return (
    <Helmet>
      <html lang="en" />
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content="business automation, machine learning, AI solutions, data analysis, mathematical modelling, business optimization, KH Solve, data science, statistical analysis, process automation UK" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:site_name" content="KH Solve" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={fullImageUrl} />
      
      {/* Additional SEO */}
      <link rel="canonical" href={fullUrl} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="KH Solve" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      
      {/* JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  imageUrl: PropTypes.string,
  type: PropTypes.string
};

export default SEO; 