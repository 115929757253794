import React from 'react';
import { Button } from 'react-bootstrap';
import ServicePageHero from './ServicePageHero';
import ServiceAttractorCanvas from './ServiceAttractorCanvas';
import { useAnalytics } from '../hooks/useAnalytics';

const ServicePage = ({ content }) => {
  useAnalytics(content.hero.title, 'service');
  
  const { hero, intro, capabilities, benefits, implementation, cta } = content;

  return (
    <div className="service-page">
      <ServiceAttractorCanvas />
      <ServicePageHero 
        title={hero.title}
        description={hero.description}
        icon={hero.icon}
      />

      <section className="service-intro">
        <div className="Container">
          <div className="service-intro-content">
            <div className="service-intro-text">
              <h2>{intro.heading}</h2>
              <p>{intro.paragraph}</p>
            </div>
            <div className="capabilities-grid">
              {capabilities.map((capability, index) => (
                <div key={index} className="capability-card">
                  <div className="capability-content">
                    <h3>{capability.title}</h3>
                    <p>{capability.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="service-benefits">
        <div className="Container">
          <h2>{benefits.heading}</h2>
          <div className="benefits-grid">
            {benefits.items.map((benefit, index) => (
              <div key={index} className="benefit-card">
                <div className="benefit-icon">
                  <img src={benefit.icon} alt={`${benefit.title} icon`} />
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="service-implementation">
        <div className="Container">
          <h2>{implementation.heading}</h2>
          <div className="implementation-steps">
            {implementation.steps.map((step, index) => (
              <div key={index} className="step-card">
                <h3>
                  <span className="step-number">{index + 1}</span>
                  {step.title}
                </h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="service-cta">
        <div className="Container">
          <h2>{cta.heading}</h2>
          <p>{cta.paragraph}</p>
          <Button 
            href="/#contact" 
            variant="primary" 
            size="lg" 
            className="custom-button"
            aria-label={`Contact us to get started with ${hero.title.toLowerCase()} solutions`}
          >
            Get Started
          </Button>
        </div>
      </section>
    </div>
  );
};

export default ServicePage; 