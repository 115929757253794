// src/components/HeroBanner.js
import React from 'react';
import { Button } from 'react-bootstrap';
import AttractorCanvas from './AttractorCanvas';
import ReactGA from 'react-ga4';

const HeroBanner = () => {
  const handleContactClick = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: 'Hero Contact Button'
    });
  };

  return (
    <header id="hero" className="hero-banner">
      <AttractorCanvas />
      <div className="content">
        <img 
          src="assets/KHlogo.svg" 
          alt="KH Solve Logo" 
          width="600"
          height="auto"
          loading="eager"
        />
        <p className="mission-statement">Empowering Your Business with Data, Automation, and AI Solutions</p>
        <Button
          href="#contact"
          className="custom-button"
          size="lg"
          aria-label="Contact us"
          onClick={handleContactClick}
        >
          Contact us
        </Button>
      </div>
    </header>
  );
};

export default HeroBanner;

