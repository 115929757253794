// src/components/DataScienceContent.js
const DataScienceContent = {
    hero: {
      title: "Data Science & Bayesian Analysis",
      description: "Transform complex data into useful insights using advanced Bayesian methods, giving your business the clarity needed to make informed decisions.",
      icon: "/assets/ds_icon.svg"
    },
    intro: {
      heading: "How Data Science Transforms Your Business",
      paragraph: "Our data science solutions combine advanced Bayesian methods with practical business insights, enabling you to make confident, data-driven decisions that drive growth and innovation."
    },
    capabilities: [
      {
        title: "Deep Data Insights",
        description: "Extract meaningful patterns and relationships from complex datasets to drive strategic decision-making"
      },
      {
        title: "Risk Quantification",
        description: "Understand and manage uncertainty with sophisticated statistical methods"
      },
      {
        title: "Strategic Planning",
        description: "Make informed decisions backed by statistical evidence and predictive modelling"
      },
      {
        title: "Growth Optimisation",
        description: "Identify and capitalise on business opportunities through data-driven insights"
      }
    ],
    benefits: {
      heading: "Why Choose Bayesian Analysis?",
      items: [
        {
          title: "Advanced Analytics",
          description: "Leverage Bayesian methods to gain deeper insights from your data, enabling more confident and precise decision-making.",
          icon: "assets/analytics_icon.svg"
        },
        {
          title: "Uncertainty Quantification",
          description: "Understand and manage risk with sophisticated statistical methods that quantify and incorporate uncertainty.",
          icon: "assets/uncertainty_icon.svg"
        },
        {
          title: "Actionable Insights",
          description: "Transform complex data into clear, actionable insights that drive strategic business decisions and growth.",
          icon: "assets/insights_icon.svg"
        }
      ]
    },
    implementation: {
      heading: "Our Implementation Process",
      steps: [
        {
          title: "Data Discovery",
          description: "We use your data sources and business objectives to identify where Bayesian analysis can provide the most valuable insights."
        },
        {
          title: "Statistical Analysis",
          description: "Our team applies advanced statistical methods to extract meaningful patterns and relationships from your data."
        },
        {
          title: "Implementation & Reporting",
          description: "We provide easy-to-understand reports that highlight the key findings from the analysis. Automated reporting systems can be set up to provide regular updates and ongoing analysis."
        }
      ]
    },
    cta: {
      heading: "Ready to Unlock Your Data's Potential?",
      paragraph: "Let's explore how data science can transform your decision-making process."
    }
  };
  
  export default DataScienceContent;