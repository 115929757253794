import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const useAnalytics = (pageName, pageType = 'general') => {
  const location = useLocation();
  const [tracked25, setTracked25] = useState(false);
  const [tracked50, setTracked50] = useState(false);
  const [tracked75, setTracked75] = useState(false);
  const [tracked100, setTracked100] = useState(false);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    // Track page view with enhanced data
    ReactGA.send({ 
      hitType: "pageview", 
      page: location.pathname,
      title: pageName,
      dimension1: window.innerWidth <= 768 ? 'mobile' : 'desktop',
      dimension2: new Date().getHours().toString(),
      dimension3: document.referrer || 'direct',
      dimension4: pageType
    });

    // Throttled scroll tracking
    let scrollTimeout;
    const trackScrollDepth = () => {
      if (scrollTimeout) return;
      
      scrollTimeout = setTimeout(() => {
        const scrollPercent = Math.round((window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100);
        const timeSpent = Math.round((Date.now() - startTime) / 1000);
        
        if (scrollPercent >= 25 && !tracked25) {
          ReactGA.event({
            category: 'Scroll Depth',
            action: `Scrolled ${pageName}`,
            label: '25%',
            value: timeSpent
          });
          setTracked25(true);
        }
        if (scrollPercent >= 50 && !tracked50) {
          ReactGA.event({
            category: 'Scroll Depth',
            action: `Scrolled ${pageName}`,
            label: '50%',
            value: timeSpent
          });
          setTracked50(true);
        }
        if (scrollPercent >= 75 && !tracked75) {
          ReactGA.event({
            category: 'Scroll Depth',
            action: `Scrolled ${pageName}`,
            label: '75%',
            value: timeSpent
          });
          setTracked75(true);
        }
        if (scrollPercent >= 100 && !tracked100) {
          ReactGA.event({
            category: 'Scroll Depth',
            action: `Scrolled ${pageName}`,
            label: '100%',
            value: timeSpent
          });
          setTracked100(true);
        }

        scrollTimeout = null;
      }, 100);
    };

    // Track page elements based on type
    const trackPageElements = () => {
      const handlers = {
        service: () => {
          const elements = {
            '.capability-card': 'Capability',
            '.benefit-card': 'Benefit',
            '.step-card': 'Implementation Step',
            '.service-cta .custom-button': 'CTA Button'
          };

          Object.entries(elements).forEach(([selector, type]) => {
            document.querySelectorAll(selector).forEach((element, index) => {
              element.addEventListener('click', () => {
                const title = element.querySelector('h3')?.textContent;
                ReactGA.event({
                  category: 'Service Page',
                  action: `${type} Click`,
                  label: `${pageName} - ${title || `${type} ${index + 1}`}`,
                  value: Math.round((Date.now() - startTime) / 1000)
                });
              });
            });
          });
        },
        'services-grid': () => {
          document.querySelectorAll('.service-link').forEach(link => {
            link.addEventListener('click', () => {
              const title = link.querySelector('h3')?.textContent;
              ReactGA.event({
                category: 'Services Grid',
                action: 'Service Click',
                label: title,
                value: Math.round((Date.now() - startTime) / 1000)
              });
            });
          });
        },
        contact: () => {
          trackFormInteractions();
        }
      };

      if (handlers[pageType]) {
        handlers[pageType]();
      }
    };

    // Enhanced form tracking
    const trackFormInteractions = () => {
      const form = document.querySelector('.contact-form');
      if (!form) return;

      // Track form focus events
      form.querySelectorAll('input, textarea').forEach(field => {
        field.addEventListener('focus', () => {
          ReactGA.event({
            category: 'Contact Form',
            action: 'Field Focus',
            label: `${field.name || field.id}`,
            value: Math.round((Date.now() - startTime) / 1000)
          });
        });
      });

      // Track form validation errors
      form.addEventListener('invalid', (e) => {
        ReactGA.event({
          category: 'Contact Form',
          action: 'Validation Error',
          label: `${e.target.name || e.target.id}: ${e.target.validationMessage}`,
          value: Math.round((Date.now() - startTime) / 1000)
        });
      }, true);

      // Track form submission attempts
      form.addEventListener('submit', (e) => {
        ReactGA.event({
          category: 'Contact Form',
          action: 'Submit Attempt',
          label: 'Contact Form',
          value: Math.round((Date.now() - startTime) / 1000)
        });
      });
    };

    // Initialize tracking
    trackPageElements();
    window.addEventListener('scroll', trackScrollDepth);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', trackScrollDepth);
      clearTimeout(scrollTimeout);
      document.querySelectorAll('.capability-card, .benefit-card, .step-card, .service-link, .service-cta .custom-button')
        .forEach(element => element.replaceWith(element.cloneNode(true)));
    };
  }, [location, pageName, pageType, tracked25, tracked50, tracked75, tracked100, startTime]);
}; 