import React from 'react';
import ServicePage from './ServicePage';
import DataScienceContent from './DataScienceContent';
import SEO from './SEO';

const DataScience = () => (
  <>
    <SEO 
      title="Data Science & Bayesian Analysis Services | KH Solve"
      description="Transform your data into actionable insights with our expert data science services. Leverage Bayesian analysis and advanced statistical methods for confident decision-making."
      path="/data-science"
      type="service"
    />
    <ServicePage content={DataScienceContent} />
  </>
);

export default DataScience; 