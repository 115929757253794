import React from 'react';
import ServicePage from './ServicePage';
import mathematicalModellingContent from './MathematicalModellingContent';
import SEO from './SEO';

const MathematicalModelling = () => (
  <>
    <SEO 
      title="Mathematical & Statistical Modelling Services | KH Solve"
      description="Optimize operations and reduce risks with our expert mathematical modelling services. Our PhD-level mathematicians create robust models for forecasting and decision-making."
      path="/mathematical-modelling"
      type="service"
    />
    <ServicePage content={mathematicalModellingContent} />
  </>
);

export default MathematicalModelling; 