import React from 'react';
import { Container } from 'react-bootstrap';

const ServicePageHero = ({ title, description, icon }) => (
  <div className="service-page-hero">
    <Container>
      <header className="service-page-header">
        <div className="service-hero-content">
          <img src={icon} alt={`${title} icon`} className="service-hero-icon" />
          <h1>{title}</h1>
          <div className="service-intro-text">
            <p>{description}</p>
          </div>
        </div>
      </header>
    </Container>
  </div>
);

export default ServicePageHero; 