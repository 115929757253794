import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useAnalytics } from '../hooks/useAnalytics';
import '../styles/terms.css';

const Terms = () => {
  useAnalytics('Terms of Service', 'legal');

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://kh-solve.co.uk/terms" />
      </Helmet>
      <Container className="terms-policy">
        <div className="terms-header">
          <h1>Terms of Service</h1>
          <p className="last-updated">Last updated: {formatDate(new Date())}</p>
        </div>

        <div className="terms-content">
          <section className="terms-section">
            <h2>1. Agreement to Terms</h2>
            <p>
              By accessing or using KH Solve's services, you agree to be bound by these Terms of Service. 
              If you disagree with any part of these terms, you may not access our services.
            </p>
          </section>

          <section className="terms-section">
            <h2>2. Services Description</h2>
            <p>
              KH Solve provides data science, machine learning, mathematical modeling, and business automation services. 
              Our services are subject to change or termination at our discretion without notice.
            </p>
          </section>

          <section className="terms-section">
            <h2>3. Intellectual Property</h2>
            <p>
              All content, features, and functionality on this website are the exclusive property of KH Solve 
              and are protected by international copyright, trademark, and other intellectual property laws.
            </p>
          </section>

          <section className="terms-section">
            <h2>4. User Responsibilities</h2>
            <p>When using our services, you agree to:</p>
            <ul>
              <li>Provide accurate and complete information</li>
              <li>Use the services in compliance with applicable laws</li>
              <li>Not attempt to breach or test our security measures</li>
              <li>Not interfere with the proper functioning of the website</li>
            </ul>
          </section>

          <section className="terms-section">
            <h2>5. Limitation of Liability</h2>
            <p>
              KH Solve shall not be liable for any indirect, incidental, special, consequential, or punitive damages 
              resulting from your use or inability to use our services.
            </p>
          </section>

          <section className="terms-section">
            <h2>6. Changes to Terms</h2>
            <p>
              We reserve the right to modify these terms at any time. We will notify users of any material changes 
              by updating the "Last Updated" date.
            </p>
          </section>

          <section className="terms-section">
            <h2>7. Contact Information</h2>
            <p>
              For questions about these Terms of Service, please contact us through our website's contact form.
            </p>
          </section>
        </div>
      </Container>
    </>
  );
};

export default Terms; 