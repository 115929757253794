import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Privacy = () => {
  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://kh-solve.co.uk/privacy" />
      </Helmet>
      <Container className="privacy-policy">
        <div className="privacy-header">
          <h1>Privacy Policy</h1>
          <p className="last-updated">Last updated: {formatDate(new Date())}</p>
        </div>

        <div className="privacy-content">
          <section className="privacy-section">
            <h2>Introduction</h2>
            <p>
              At KH Solve, we respect your privacy and are committed to protecting your personal data. 
              This privacy policy explains how we collect, use, and safeguard your information when you visit our website.
            </p>
          </section>

          <section className="privacy-section">
            <h2>Information We Collect</h2>
            <h3>Analytics Data</h3>
            <p>
              We use Google Analytics to understand how visitors interact with our website. This service collects:
            </p>
            <ul>
              <li>Pages visited and time spent on each page</li>
              <li>Technical information such as browser type and device type</li>
              <li>Approximate geographic location (country/city level)</li>
              <li>Referral sources</li>
              <li>Scroll depth and page interaction metrics</li>
              <li>Form interaction data (field focus, validation errors)</li>
              <li>Time of day usage patterns</li>
              <li>Device type (mobile/desktop)</li>
              <li>Service and feature interaction patterns</li>
            </ul>

            <h3>Contact Form Data</h3>
            <p>
              When you use our contact form, we collect:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Any additional information you provide in your message</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2>How We Use Your Information</h2>
            <p>We use the collected information to:</p>
            <ul>
              <li>Improve our website and services</li>
              <li>Respond to your inquiries</li>
              <li>Analyze and understand how our website is used</li>
              <li>Maintain the security and functionality of our website</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2>Cookies</h2>
            <p>
              We use cookies to enhance your browsing experience and analyze website traffic. 
              You can control cookie preferences through your browser settings and our cookie consent banner.
            </p>
            <h3>Types of Cookies We Use:</h3>
            <ul>
              <li><strong>Essential Cookies:</strong> Required for basic website functionality</li>
              <li><strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2>Data Security</h2>
            <p>
              We implement appropriate security measures to protect your personal information. 
              However, please note that no method of transmission over the internet is 100% secure.
            </p>
          </section>

          <section className="privacy-section">
            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal data</li>
              <li>Correct inaccurate personal data</li>
              <li>Request deletion of your personal data</li>
              <li>Object to our processing of your personal data</li>
              <li>Withdraw consent for data processing</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2>Contact Us</h2>
            <p>
              For any questions about this privacy policy or our data practices, please contact us at:
            </p>
            <p>Email: contact@kh-solve.co.uk</p>
          </section>
        </div>
      </Container>
    </>
  );
};

export default Privacy; 