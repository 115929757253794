// src/components/Contact.js
import React, { useState, useRef, useEffect, useMemo, lazy, Suspense, memo } from 'react';
import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../hooks/useAnalytics';

const LazyAlert = lazy(() => import('react-bootstrap/Alert'));

const LoadingSpinner = memo(() => (
  <div className="loading-spinner" role="status">
    <span className="sr-only">Loading...</span>
  </div>
));

class FormErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h3>Something went wrong with the form.</h3>
          <button onClick={() => window.location.reload()}>
            Reload Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const Contact = () => {
  useAnalytics('Contact Form', 'contact');
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);
  
  const formRef = useRef(null);
  const successMessageRef = useRef(null);

  useEffect(() => {
    if (submitStatus?.type === 'success') {
      successMessageRef.current?.focus();
    }
  }, [submitStatus]);

  const validateForm = useMemo(() => (formData) => {
    const errors = {};
    const name = formData.get('name');
    const email = formData.get('_replyto');
    const message = formData.get('message');

    if (!name?.trim()) errors.name = 'Name is required';
    if (!email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    if (!message?.trim()) errors.message = 'Message is required';

    return errors;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      requestAnimationFrame(() => {
        const firstErrorField = form.querySelector('.is-invalid');
        if (firstErrorField) {
          firstErrorField.focus();
        }
      });
      return;
    }

    setIsSubmitting(true);
    setFormErrors({});

    try {
      const response = await fetch('https://formspree.io/f/mkgwdvra', {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: formData,
      });

      if (response.ok) {
        setIsSubmitted(true);
        setSubmitStatus({
          type: 'success',
          message: 'Thank you for your message! We will get back to you soon.'
        });
        form.reset();
        setFormErrors({});
        setIsSubmitting(false);
        setIsSubmitted(true);

        // Track successful submission
        ReactGA.event({
          category: 'Contact',
          action: 'Form Submit Success',
          label: 'Contact Form'
        });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setSubmitStatus({
        type: 'danger',
        message: error.message
      });

      // Track failed submission
      ReactGA.event({
        category: 'Contact',
        action: 'Form Submit Failed',
        label: 'Contact Form'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const useFormPerformance = () => {
    useEffect(() => {
      const startTime = performance.now();
      
      return () => {
        const endTime = performance.now();
        console.log(`Form render time: ${endTime - startTime}ms`);
      };
    }, []);
  };

  useFormPerformance();

  return (
    <FormErrorBoundary>
      <section id="contact" className="contact" aria-labelledby="contact-heading">
        <Container>
          <h2 id="contact-heading">Contact Us</h2>
        <p>
          Book your <strong>free</strong> 1-hour consultation today.
        </p>
          {isSubmitting && <LoadingSpinner />}
          {submitStatus && (
            <Suspense fallback={<LoadingSpinner />}>
              <LazyAlert 
                variant={submitStatus.type} 
                role="alert"
                tabIndex={-1}
                ref={successMessageRef}
              >
                {submitStatus.message}
              </LazyAlert>
            </Suspense>
          )}
          <form 
            ref={formRef}
            onSubmit={handleSubmit} 
            className="contact-form" 
            aria-label="Contact form"
            noValidate
          >
            <input type="hidden" name="_subject" value="New submission from KH Solve Contact Form" />
            <input type="hidden" name="_captcha" value="false" />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />

            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name<span className="sr-only"> (required)</span>
              </label>
              <input
                type="text"
                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                placeholder="Your Name"
                required
                disabled={isSubmitting || isSubmitted}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email<span className="sr-only"> (required)</span>
              </label>
              <input
                type="email"
                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                id="email"
                name="_replyto"
                placeholder="Your Email"
                required
                aria-required="true"
                aria-invalid={!!formErrors.email}
                aria-describedby={formErrors.email ? 'email-error' : undefined}
                disabled={isSubmitting || isSubmitted}
              />
              {formErrors.email && (
                <div id="email-error" className="invalid-feedback" role="alert">
                  {formErrors.email}
                </div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message<span className="sr-only"> (required)</span>
              </label>
              <textarea
                className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                id="message"
                name="message"
                rows="3"
                placeholder="Your Message"
                required
                disabled={isSubmitting || isSubmitted}
              ></textarea>
            </div>

            <button 
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
              aria-busy={isSubmitting}
              aria-live="polite"
            >
              <span aria-hidden={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </span>
              {isSubmitting && (
                <span className="sr-only">Submitting form, please wait...</span>
              )}
            </button>
          </form>
        </Container>
      </section>
    </FormErrorBoundary>
  );
};

export default memo(Contact);
