// src/components/MachineLearningContent.js
const MachineLearningContent = {
    hero: {
      title: "Machine Learning & AI Solutions",
      description: "Unlock the potential of your data with advanced AI solutions that drive innovation, improve efficiency, and give your business a competitive edge.",
      icon: "/assets/ml_icon.svg"
    },
    intro: {
      heading: "How AI Transforms Your Business",
      paragraph: "Our AI and machine learning solutions provide cutting-edge capabilities to automate, optimise, and enhance your business operations, driving innovation and competitive advantage."
    },
    capabilities: [
      {
        title: "Intelligent Automation",
        description: "Automate complex tasks and processes with AI-powered solutions"
      },
      {
        title: "Predictive Analytics",
        description: "Forecast trends and anticipate changes with machine learning models"
      },
      {
        title: "Process Optimisation",
        description: "Enhance efficiency and resource allocation with AI-driven insights"
      },
      {
        title: "Smart Decision Making",
        description: "Make data-driven decisions powered by advanced AI algorithms"
      }
    ],
    benefits: {
      heading: "Why Choose AI Solutions?",
      items: [
        {
          title: "Intelligent Automation",
          description: "Leverage AI to automate complex tasks and decision-making processes, improving efficiency and reducing costs.",
          icon: "assets/automation_icon.svg"
        },
        {
          title: "Predictive Analytics",
          description: "Harness the power of machine learning to forecast trends and anticipate market changes with greater accuracy.",
          icon: "assets/predictive_icon.svg"
        },
        {
          title: "Smart Optimisation",
          description: "Use AI-driven insights to optimise operations and resource allocation in real-time.",
          icon: "assets/optimization_icon.svg"
        }
      ]
    },
    implementation: {
      heading: "Our Implementation Process",
      steps: [
        {
          title: "Requirements Analysis",
          description: "We work with you to identify opportunities where AI can provide the most value for your business."
        },
        {
          title: "Model Development",
          description: "Our team develops and trains custom AI models tailored to your specific needs and objectives."
        },
        {
          title: "Integration & Deployment",
          description: "We seamlessly integrate AI solutions into your existing systems and provide ongoing support."
        }
      ]
    },
    cta: {
      heading: "Ready to Embrace AI Innovation?",
      paragraph: "Let's explore how AI and machine learning can transform your business operations."
    }
  };
  
  export default MachineLearningContent;