// src/components/Services.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../hooks/useAnalytics';

const services = [
  {
    img: 'assets/ds_icon.svg',
    title: 'Data Science & Bayesian Analysis',
    description:
      'Turn your data into clear, actionable insights. Our team helps your business understand its data, using methods like Bayesian analysis, so you can make informed decisions with confidence.',
    path: '/data-science'
  },
  {
    img: 'assets/ml_icon.svg',
    title: 'Machine Learning & AI',
    description:
      'From predictive modelling to AI-driven automation, we use the latest machine learning techniques to give your business the advantage it deserves.',
    path: '/machine-learning'
  },
  {
    img: 'assets/math_icon.svg',
    title: 'Mathematical & Statistical Modelling',
    description:
      'Our mathematical models offer strong solutions for optimising your operations, forecasting trends, and reducing risks, helping you make business decisions backed by real data and insights.',
    path: '/mathematical-modelling'
  },
  {
    img: 'assets/autom_icon.svg',
    title: 'Business Process Automation',
    description:
      'Let technology handle repetitive, time-consuming tasks automatically and free up your team to focus on what really drives your business forward.',
    path: '/business-automation'
  },
];

const ServiceCard = ({ service }) => (
  <Link to={service.path} className="service-link">
    <article className="service-card">
      <div className="service-icon-wrapper">
        <img src={service.img} alt={`${service.title} Icon`} className="service-icon" />
      </div>
      <div className="service-content">
        <h3 className="text-center">{service.title}</h3>
        <p className="text-center">{service.description}</p>
      </div>
      <div className="service-footer">
        <span className="learn-more">
          Learn more
          <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
      </div>
    </article>
  </Link>
);

const Services = () => {
  useAnalytics('Services', 'services-grid');

  return (
    <section id="services" className="services" aria-labelledby="services-heading">
      <Container>
        <h2 id="services-heading" className="section-title text-center">Services</h2>
        <Row className="services-grid">
          {services.map((service, index) => (
            <Col md={6} key={index} className="service-column">
              <ServiceCard service={service} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Services;

