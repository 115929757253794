import React from 'react';
import ServicePage from './ServicePage';
import businessAutomationContent from './BusinessAutomationContent';
import SEO from './SEO';

const BusinessAutomation = () => (
  <>
    <SEO 
      title="Business Process Automation Services | KH Solve"
      description="Transform your business operations with our expert automation solutions. Streamline workflows, reduce errors, and boost efficiency with KH Solve's automation services."
      path="/business-automation"
      type="service"
    />
    <ServicePage content={businessAutomationContent} />
  </>
);

export default BusinessAutomation; 