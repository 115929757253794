// src/components/MathematicalModellingContent.js
const MathematicalModellingContent = {
    hero: {
      title: "Mathematical Modelling",
      description: "Transform complex problems into clear mathematical frameworks, enabling precise analysis and optimal decision-making.",
      icon: "/assets/math_icon.svg"
    },
    intro: {
      heading: "How Mathematical Modelling Drives Success",
      paragraph: "Our mathematical modelling solutions provide powerful frameworks for analysis and optimisation, enabling you to solve complex problems and optimise your business processes."
    },
    capabilities: [
      {
        title: "Complex Problem Solving",
        description: "Transform real-world challenges into solvable mathematical frameworks"
      },
      {
        title: "Process Optimisation",
        description: "Optimise systems and workflows through precise mathematical analysis"
      },
      {
        title: "Predictive Analysis",
        description: "Make data-driven predictions using advanced mathematical models"
      },
      {
        title: "System Understanding",
        description: "Gain deep insights into complex system dynamics and relationships"
      }
    ],
    benefits: {
      heading: "Why Choose Mathematical Modelling?",
      items: [
        {
          title: "Complex Problem Solving",
          description: "Transform real-world challenges into mathematical frameworks for clear, actionable solutions.",
          icon: "assets/puzzle_icon.svg"
        },
        {
          title: "Predictive Capabilities",
          description: "Leverage advanced mathematical models to forecast trends and optimise decision-making.",
          icon: "assets/predictive_icon.svg"
        },
        {
          title: "System Optimisation",
          description: "Identify and implement improvements through precise mathematical analysis and modelling.",
          icon: "assets/optimization_icon.svg"
        }
      ]
    },
    implementation: {
      heading: "Our Implementation Process",
      steps: [
        {
          title: "Problem Analysis",
          description: "We work with you to understand the core challenges and define clear mathematical objectives."
        },
        {
          title: "Model Development",
          description: "Our team develops and validates sophisticated mathematical models tailored to your needs."
        },
        {
          title: "Implementation & Results",
          description: "We implement solutions and provide clear interpretations of mathematical insights."
        }
      ]
    },
    cta: {
      heading: "Ready to Transform Your Challenges?",
      paragraph: "Let's explore how mathematical modelling can optimise your business processes."
    }
  };
  
  export default MathematicalModellingContent;